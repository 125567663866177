import React, { CSSProperties, PropsWithChildren } from 'react';
import { KeyboardBackspace } from '@mui/icons-material';
import LaunchIcon from '@mui/icons-material/Launch';
import { Box, Typography, TypographyProps } from '@mui/material';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';

import MainMenu from './MainMenu';

export const PageTitle = (props: TypographyProps) => (
    <Typography sx={{
        textAlign: 'center',
        marginBottom: 2,
        fontWeight:400,
    }} variant="h2" {...props}>
    </Typography>
);

const returnLogoLink = (linkToBes: boolean, linkToStartPage: boolean) => {
    switch (true) {
        case linkToBes:
            return 'https://inspectgroup.se/';
        case linkToStartPage:
            return '/';
        default :
            return '/portal';
    }
};

export const Layout = (
    {
        children,
        title,
        mainStyle = {},
        startAtTop = false,
        includePrivacyPolicy = false,
        includeBanner = false,
        includeBackLink = false,
        linkToStartPage = false,
        linkToBes = false,
        hideMenu = false,
        showBackground = false,
    }: PropsWithChildren<{
        title?: string;
        startAtTop?: boolean;
        mainStyle?: React.CSSProperties;
        includePrivacyPolicy?: boolean;
        includeBanner?: boolean;
        includeBackLink?: boolean;
        linkToStartPage?: boolean;
        linkToBes?: boolean;
        hideMenu?: boolean;
        showBackground?: boolean;
    }>) => {
    const getPaddingBelowMenu = () => {
        if (includeBanner) {
            return '2rem 0';
        }

        if (startAtTop) {
            return undefined;
        }

        if (hideMenu) {
            return '3rem 0';
        }

        return '10rem 0';
    };

    const style: CSSProperties = {
        ...{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
        },
        ...(showBackground ? {
            backgroundImage: 'url("/houses-background.svg")',
            backgroundPosition: 'bottom',
            backgroundRepeat: 'repeat-x',
            backgroundSize: '8000px',
        } : {}),
    };

    return (
        <>
            <Head>
                <title>Besiktningssystemet</title>
            </Head>
            <main
                style={style}
            >
                {includeBanner && (
                    <Box height="378px" width="100%" maxHeight="450px" position="relative">
                        <Image
                            src="/bes-banner.jpeg"
                            alt="Banner"
                            fill
                            style={{
                                objectPosition: '50% 17.5%',
                                objectFit: 'cover',
                            }}
                        />
                    </Box>
                )}
                <Box
                    id="system-header"
                    sx={{
                        position: 'fixed',
                        zIndex: 10,
                        top: 0,
                        left: 0,
                        right: 0,
                        padding: { xs: '20px 24px', md: '23px 32px' },
                        marginBottom: '40px',
                        borderBottom: '1px solid #dcdcdc',
                        backgroundColor: 'background.default',
                        display: 'flex',
                        justifyContent: { xs: 'flex-start', md: 'center' },
                        alignItems: 'center',
                    }}
                >
                    <Box
                        component={Link}
                        href={returnLogoLink(linkToBes, linkToStartPage)}
                        sx={{
                            display: 'flex',
                            width: { xs: '140px', md: '188px' },
                            marginRight: { xs: 'auto', md: 0 },
                            'img': {
                                width: '100%',
                                height: 'auto',
                            },
                        }}>
                        <Image
                            src="/inspect-group-logo.svg"
                            alt="Inspect Group"
                            width={188}
                            height={31.63}
                        />
                    </Box>
                    {includeBackLink && (
                        <Box
                            component={Link}
                            href={'/'}
                            sx={{
                                fontFamily: 'Poppins, sans-serif',
                                fontSize: '0.875rem',
                                fontWeight: 600,
                                display: 'inline-flex',
                                alignItems: 'center',
                                gap: '7px',
                                position: { md: 'absolute' },
                                left: { md: '24px' },
                            }}>
                            <KeyboardBackspace />
                            Tillbaka
                        </Box>
                    )}
                    {!hideMenu && (
                        <Box
                            component={MainMenu}
                            sx={{
                                position: { md: 'absolute' },
                                right: { md: '65px' }
                            }}
                        />
                    )}
                </Box>
                <Box
                    sx={{
                        padding: getPaddingBelowMenu(),
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'start',
                        flexDirection: 'column',
                        alignItems: 'center',
                        ...mainStyle,
                    }}
                >
                    {title && <PageTitle>{title}</PageTitle>}
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            maxWidth: '1400px',
                            width: '100%',
                            height: '100%',
                            padding: '2em',
                            margin: '0 auto 0',
                        }}
                    >
                        {children}
                    </Box>
                    {includePrivacyPolicy && (
                        <Box sx={{ marginTop: 2 }}>
                            <Link
                                style={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins, sans-serif' }}
                                href="https://inspectgroup.se/integritetspolicy"
                            >
                                Integritetspolicy
                                <LaunchIcon sx={{ marginLeft: 1 }} />
                            </Link>
                        </Box>
                    )}
                </Box>
            </main>
        </>
    );
};

export default Layout;
