import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { PageTitle } from '../components/common/PageTitle';
import { StandardButton } from '../components/common/StandardButton';
import Layout from '../layouts/Layout';

export default function Authentication() {
    const { t } = useTranslation('common');

    return (
        <Layout
            includePrivacyPolicy
            includeBanner
            linkToStartPage
        >
            <Box data-non-pii sx={{ maxWidth: '688px', marginInline: 'auto', marginTop: '-22px' }}>
                <PageTitle>{t('/.title')}</PageTitle>
                <Trans
                    i18nKey="/.body"
                    t={t}
                    components={{
                        h2: (
                            <Typography
                                variant="h2"
                                gutterBottom
                                sx={{
                                    fontSize: '1.25rem',
                                    fontWeight: 600,
                                    color: '#000',
                                    marginTop: '1.8rem',
                                    marginBottom: '1.125rem'
                                }}
                            />
                        ),
                        p: <Typography sx={{ fontWeight: 300, marginBottom: '1rem' }} />,
                        infobox: (
                            <Box
                                sx={{
                                    backgroundColor: '#F5F5F5',
                                    padding: '17px',
                                    borderRadius: '8px',
                                    marginTop: '1.5rem'
                                }}>
                                <Typography sx={{ fontSize: '0.875rem' }} />
                                <Box
                                    component="ul"
                                    sx={{
                                        fontSize: '0.875rem',
                                        fontWeight: 300,
                                        marginBottom: 0,
                                        paddingLeft: '28px',
                                        '& > li': { paddingLeft: '8px' },
                                    }}>
                                    <li />
                                    <li />
                                    <li />
                                    <li />
                                    <li />
                                </Box>
                            </Box>
                        ),
                    }}
                />
                <Box sx={{ textAlign: 'center', marginTop: 5 }}>
                    <StandardButton href="/auth/login">
                        {t('/.loginButton')}
                    </StandardButton>
                </Box>
            </Box>
        </Layout>
    );
}

export async function getStaticProps(context: { locale: string }) {
    const { locale = 'se' } = context;

    return {
        props: {
            ...(await serverSideTranslations(locale, ['common'])),
        },
    };
}
