import { PropsWithChildren } from 'react';
import { Typography } from '@mui/material';

export const PageTitle = ({ children, cyId }: PropsWithChildren<{ cyId?: string }>) => (
    <Typography
        variant="h1"
        data-cy={cyId}
        sx={{
            textAlign: 'left',
            paddingBottom: '1.125rem',
            marginBottom: '1.5rem',
            fontWeight: 600,
            fontSize: { xs: '1.75rem', md: '2.125rem' },
            color: 'inherit',
            position: 'relative',
            '&::after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                bottom: 0,
                left: 0,
                height: '2px',
                width: '100px',
                backgroundColor: '#4FADE6',
            },
        }}>
        {children}
    </Typography>
);
