import { Box } from '@mui/material';
import Link from 'next/link';

const MainMenu = (props: { className?: string }) => {
    return (
        <nav className={props.className}>
            <Box
                component={Link}
                href="/auth/login"
                sx={{
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: '0.875rem',
                    fontWeight: 600
                }}>
                Logga in
            </Box>
        </nav>
    );
};

export default MainMenu;
