import { PropsWithChildren, ReactNode } from 'react';
import { ButtonBase, SxProps } from '@mui/material';
import Link from 'next/link';

interface Props {
    sx?: SxProps;
    className?: string;
    id?: string;
    cyId?: string;
    href?: string;
    disabled?: boolean;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    onClick?: () => void;
}

export const StandardButton = ({
    children,
    sx,
    className,
    id,
    cyId,
    href,
    disabled,
    startIcon,
    endIcon,
    onClick
}: PropsWithChildren<Props>) => (
    <ButtonBase
        component={href ? Link : 'button'}
        href={href}
        onClick={onClick}
        disabled={disabled}
        className={className}
        id={id}
        data-cy={cyId}
        sx={{
            backgroundColor: '#4FADE6',
            color: '#fff',
            padding: '9px 25px',
            fontFamily: 'Poppins, sans-serif',
            fontSize: '0.875rem',
            fontWeight: 600,
            borderRadius: '21px',
            border: '1.5px solid #4FADE6',
            gap: '5px',
            transition: (theme) => {
                return theme.transitions.create('background-color', {
                    duration: theme.transitions.duration.short
                });
            },
            '&:disabled': {
                backgroundColor: '#BBDEFB',
                borderColor: '#BBDEFB',
                color: '#999999',
            },
            '&:hover:not(:disabled)': {
                backgroundColor: '#1E88E5',
                borderColor: '#1E88E5',
            },
            '&.Mui-focusVisible': {
                borderColor: '#1E88E5',
            },
            '& .MuiSvgIcon-root': {
                fontSize: '1.2em',
            },
            ...sx,
        }}>
        {startIcon}
        {children}
        {endIcon}
    </ButtonBase>
);
